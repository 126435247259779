<template>
    <div class="free mb-5">
        <div v-for="(item,index) in box_list" :key="index" class="free-content bg-size-100 bg-repeat">
 
            <div class="free-content-right mt-5">
                <div class="welfares-header family">
                   开启宝箱
                    <!-- {{resData.name}} -->
                </div>


                <div class="free-content-right-con d-flex justify-content-center">
                    <LuckyGrid :ref="`LuckyGridClick${index}`"
                      style="background-color: #9FDA35;"
                               :width="sUserAgentCom == 'phone'?'350px':'1200px'"
                               :height="sUserAgentCom == 'phone'?'300px':'500px'"
                               rows="3"
                               cols="4"
                               :demo="true"
                               :blocks="blocks"
                               :prizes="item.prizes"
                               :buttons="buttons"
                               :active-style="defaultStyle"
                               @end="endCallBack"/>
                </div>
                <div class="" style="width:100%;margin:10px auto">
                    <div v-if="type == 4" class="position-relative">
                        <div   >

                            <div style="background-color:#021B3D; border-radius: 5px; height:48px;line-height:43px; width:60%;margin:auto ">
                                <input style="width:100%;font-size: 15px; text-align:center;" class="text-white" type="text" v-model="cdk"
                                       placeholder="请输入CDK兑换码">
                            </div>
                        </div>
                    </div>
                    <div style="width: 60%; margin:15px auto;">
                        <div>
                            <bottom-bg3 @click.native="startCallBack(item,index)">
                                <div class="open-box" style="height: 23px; line-height: 23px;">
                                    {{type == 4 ? 'CDK抽奖':'免费抽奖'}}
                                </div>
                            </bottom-bg3>
                        </div>
                    </div>

                </div>
                <div v-if="type == 4" class="roll-title">
                    新人找客服领取CDK
                </div>
            </div>

        </div>


        <div v-if="openShow">
            <div style=""
                 class="awardListPop position-fixed pop-up z-index-100 py-5 px-4 history_bg">
                <div>
                    <div class="h5 text-center">Good Luck</div>
                    <div class="awardListPop-box d-flex justify-content-center flex-wrap ">
                        <div style="height:fit-content"
                             class="text-center award-box ggbg2 pt-md-3 pt-1">
                            <img width="80%" :src="drawDetail.imgs[0].src" alt="">
                            <div class="my-1 text-overflow-1">{{drawDetail.fonts[1].text}}</div>
                            <div class="my-2 text-overflow-1">{{drawDetail.fonts[0].text}}</div>
                        </div>
                    </div>
                    <div class="d-flex px-md-5 justify-content-center mt-4">
                        <div @click="openShowOff">
                            <bottom-bg2>
                                <div style="color: #FFFFFF;font-size: 12px">
                                    收下饰品
                                </div>
                            </bottom-bg2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
    </div>
</template>

<script>
  import sUserAgent from '@/assets/js/common.js'
  import bottomBg3 from "@/components/bottomBg3";
  import {mapState} from 'vuex'
  import $api from '@/api/outOfTheFree/index'

  export default {
    name: "active",
    data() {
      return {
        drawDetail: {},
        openShow: false,
        blocks: [
          {padding: '12px 19px', background: 'rgba(34,34,34,.5)'},
        ],
        defaultStyle: {background: 'rgba(173,37,44,.3)'},
        prizes: [],
        buttons: [{}],
        buttonsRepetition: true,
        activity_rules: false,
        box_list: [],
        cdk: '',
        box_gun_active: {},
        box_gun_active_is_show: false,
        box_id: '',
        type: 0,
        resData: {}
      }
    },
    components: { bottomBg3 },
    computed: {
      ...mapState(['user_info']),
      sUserAgentCom() {
        return sUserAgent.sUserAgent()
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      async init() {
        await this.getWelfare()
        this.box_id = this.$route.params.id
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        console.log(this.$route.query)

      },
      async getWelfare() {
        try {
          let item = {}
          let box_id = this.$route.params.id
          let {data, code} = await $api.get_box_detail(box_id)
          console.log(data)
          if (code == 500) return
          this.resData = data
          item.bean = data.bean || 0
          item.box_list = data.contains
          let obj = this.welfareDataPrizes(item)
          item.prizes = obj
          this.box_list.push(item)
        } catch (e) {
          console.log(e)
        }
      },
      welfareDataPrizes(resData) {
        let data = JSON.parse(JSON.stringify(resData))
        let obj = data.box_list.map((item, index) => {
          let obj = {
            skin_id: item.skins.id,
            borderRadius: 1,
            x: index >= 4 ? index - 4 >= 4 ? index - 8 : index - 4 : index,
            y: index < 4 ? 0 : index < 8 ? 1 : 2,
            fonts: [
              {
                text: '金币' + item.skins.bean,
                fontSize: this.sUserAgentCom == 'phone' ? '8px' : '12px',
                top: this.sUserAgentCom == 'phone' ? 10 : 20,
                fontColor: '#FFFFFF'
              },
              {
                text: item.skins.name,
                fontSize: this.sUserAgentCom == 'phone' ? '8px' : '12px',
                top: this.sUserAgentCom == 'phone' ? 60 : 130,
                fontColor: '#FFFFFF'
              },
            ],
            imgs: [{
              src: item.skins.cover,
              width: this.sUserAgentCom == 'phone' ? 50 : 150,
              height: this.sUserAgentCom == 'phone' ? 41 : 90,
              top: this.sUserAgentCom == 'phone' ? 15 : 30,
            }],
            background: 'rgba(0,0,0,.3)',
          }
          return obj
        })
        return obj
      },

      async startCallBack(item, index) {

        if (this.buttonsRepetition) {
          this.buttonsRepetition = false
        }
        console.log(item, index)
        let openData = {
          id: this.id
        }
        if (this.type == 4) {
          openData.cdk = this.cdk
        }
        let {data, code} = await $api.get_welfare_open(openData)
        if (code == 500) return
        let i = this.box_list[index].box_list.findIndex(t => {
          return t.skin_id == data.skin_id
        })
        let luck_grid = this.$refs[`LuckyGridClick${index}`]
        luck_grid[0].play()
        // let sum = Math.random() * 12
        setTimeout(() => {
          luck_grid[0].stop(i)
        }, 2000)
      },
      endCallBack(prize) {
        this.buttonsRepetition = true
        this.box_gun_active_is_show = true
        this.box_gun_active = prize
        this.drawDetail = prize
        setTimeout(() => {
          this.openShow = true
        }, 500)


        console.log(prize)
      },
      openShowOff() {
        this.openShow = false
        this.cdk = ''
      }
    }
  }
</script>

<style lang="scss" scoped>
.welfares-header {
  width: 310px;
  height: 71px;
  margin: 25px auto;
  background: url('../../assets/image/openbox/green.png');
  background-size: 100% 100%;
  // background: linear-gradient(146deg, #009FFF 0%, #009FFF 0%, #05377B 100%);
  // box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  line-height: 71px;
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-text-stroke: 1px black;
}
    .open-box {
        color: #FFFFFF;
        font-size: 14px;
        @media (max-width: 1200px) {
            font-size: 14px
        }
    }

    .award-box {
        box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, .5);
        width: 100%;
        margin: 10px 0.5%;
        @media (max-width: 1200px) {
            width: 48%;
            margin: 10px 0.5%;
        }
    }

    .awardListPop {
        width: 700px;
        left: calc(50% - 350px);
        top: 25vh;
        z-index: 1002;
        position: relative;
        @media (max-width: 1200px) {
            width: 98%;
            left: 1%;
        }

        &-box {

        }
    }

    .free {
        width: 80%;
        margin: 0 auto;
        padding: 0 10px;
        @media (max-width: 1200px) {
            width: 100%;
        }

        &-content {
            width: 100%;

            &-left {
                width: 100%;
                margin: 50px auto;
                z-index: 3;
                position: relative;

                &-btn {
                    width: 150px;
                    height: 50px;

                }
            }

            &-right {
                &-con {
                    width: 100%;
                    margin: 0 auto;

                    & .active {

                    }
                }
            }
        }
    }
    .roll-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 1px #98d462;
  text-align: center;
  font-weight: bolder;
  font-style: italic;
}
</style>
